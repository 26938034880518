import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Link, useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { SEO, ImageCircle } from '../components'
import RAINA from '../images/rain-a.jpg'
import RAINB from '../images/rain-b.jpg'
import RAINC from '../images/rain-c.jpg'
import Plant from '../images/plant.png'
import PlantIcon from '../images/icons/Plant_128.png'
import WaterHose from '../images/icons/Water_Hose_128.png'
import LawnMower from '../images/icons/Lawn_Mower_128.png'

const useStyles = makeStyles(theme => ({
  jumbo: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const IndexPage = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query Pictures {
      allFile(filter: { relativeDirectory: { glob: "gallery/*" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1280, quality: 90) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  `)

  const murki = [
    { dir: 'gallery/JedrzejowI', name: '1 po' },
    { dir: 'gallery/JedrzejowI', name: '2 po' },
    { dir: 'gallery/Piaseczna', name: 'IMG_0009' },
    { dir: 'gallery/Wola', name: '100_0011' },
    { dir: 'gallery/Sandomierz', name: 'h' },
    { dir: 'gallery/Sandomierz', name: 'd' },
  ]

  const rosliny = [
    { dir: 'gallery/Wola', name: '100_0009' },
    { dir: 'gallery/Nadarzyn', name: 'h' },
    { dir: 'gallery/Sandomierz', name: 'c' },
    { dir: 'gallery/KielceII', name: 'P1080484' },
    { dir: 'gallery/JedrzejowI', name: 'f' },
    { dir: 'gallery/KielceII', name: 'b' },
  ]

  return (
    <>
      <SEO title='Home' />
      <section className={cn(classes.jumbo, 'text-white')}>
        <div className='container mx-auto flex py-8'>
          <div className='flex-1 relative hidden md:block'>
            <ImageCircle src={RAINA} alt='a' size={64} />
            <ImageCircle src={RAINC} alt='c' size={48} style={{ left: '235px', top: '100px' }} />
            <ImageCircle src={RAINB} alt='b' size={32} style={{ left: '200px', top: '20px' }} />
          </div>
          <div className='flex-1 flex flex-col justify-center'>
            <div className='text-3xl mb-4'>SYSTEMY AUTOMATYCZNEGO NAWADNIANIA</div>
            <p className='text-xl my-2 md:my-0'>
              - nawadnianie ogrodów przydomowych i boisk piłkarskich.
            </p>
            <p className='text-xl my-2 md:my-0'>- montaż, serwis i konserwacja systemów.</p>
            <p className='text-xl my-2 md:my-0'>- melioracja, niwelacja terenu.</p>
          </div>
        </div>
      </section>
      <section className='bg-gray-200'>
        <div className='container mx-auto flex flex-col justify-center h-48 relative'>
          <h2 className='text-center text-lg mb-4 z-10'>Witamy na stronie firmy Eko-Bursztyn</h2>
          <p className='text-center text-sm z-10 subpixel-antialiased'>
            Jesteśmy firmą działającą w branży ogrodniczej. Swoje usługi świadczymy już od{' '}
            <b>piętnastu lat</b> - zawsze profesjonalnie i z najwyższą jakością. Oferujemy usługi w
            zakresie tworzenia i zarządzania systemami zielonymi.
          </p>
          <img
            className='absolute w-48 h-48 m-0 right-0 top-0 hidden md:block'
            src={Plant}
            style={{ top: '-46px' }}
          />
        </div>
      </section>
      <section className='py-4 px-4'>
        <div className='max-w-sm w-full max-w-full flex'>
          <div className='flex-1 flex flex-col p-4 mr-4'>
            <div className='flex items-center font-bold text-base mb-2'>
              <img className='w-8 h-8 m-0' src={WaterHose} />
              <span className='ml-2'>Nawadnianie terenu</span>
            </div>
            <p className='text-gray-700 md:text-base text-sm mb-4'>
              Do przygotowania systemu nawadniania nie potrzeba drogich projektów. Można zacząć od
              prostego szkicu na podstawie którego przygotowywany jest ogólny kosztorys.
            </p>
            <Link className='btn btn-blue mt-auto' to='/nawadnianie'>
              Więcej
            </Link>
          </div>
          <div className='flex-1 flex flex-col p-4 mr-4'>
            <div className='flex items-center font-bold text-base mb-2'>
              <img className='w-8 h-8 m-0' src={PlantIcon} />
              <span className='ml-2'>Wycena projektów</span>
            </div>
            <p className='text-gray-700 md:text-base text-sm mb-4'>
              Zajmujemy się wyceną ogrodów zaprojektowanych przez różnych architektów. Pomagamy
              prawidłowo zaplanować kolejność prac, oraz koszty związane z zagospodarowaniem terenu.
            </p>
            <Link className='btn btn-blue mt-auto' to='/kontakt'>
              Zapytaj
            </Link>
          </div>
          <div className='flex-1 flex flex-col p-4 mr-0'>
            <div className='flex items-center font-bold text-base mb-2'>
              <img className='w-8 h-8 m-0' src={LawnMower} />
              <span className='ml-2'>Trawniki z rolki</span>
            </div>
            <p className='text-gray-700 md:text-base text-sm mb-4'>
              Trawnik często pełni rolę reprezentacyjną, dlatego istotny jest dobór odpowiednich
              gatunków traw, a także wybór podłoża oraz przygotowanie stanowiska.
            </p>
            <Link className='btn btn-blue mt-auto' to='/trawniki'>
              Więcej
            </Link>
          </div>
        </div>
      </section>

      <section className='py-4 px-4 bg-gray-200'>
        <div className='max-w-sm w-full max-w-full flex'>
          <div className='flex-1 w-1/3 p-4 overflow-hidden flex flex-col'>
            <div className='flex items-center font-bold text-sm mb-2'>Murki Oporowe</div>
            <p className='text-gray-800 md:text-base text-sm mb-4'>
              Wykonujemy murki oporowe z piaskowca, granitu i kwarcytu.
            </p>
            <Link className='btn btn-blue mt-auto mb-2' to='/kontakt'>
              Kontakt
            </Link>
          </div>
          <div className='w-2/3 p-4 flex flex-wrap justify-start leading-normal'>
            {data.allFile.edges
              .filter(({ node }) =>
                murki.find(item => item.name === node.name && item.dir === node.relativeDirectory),
              )
              .map(({ node }) => (
                <GatsbyImage
                  className='w-56 h-56 mr-2 mb-2 border-solid border-white border-4 rounded-sm'
                  fluid={node.childImageSharp.fluid}
                />
              ))}
          </div>
        </div>
      </section>

      <section className='py-4 px-4'>
        <div className='max-w-sm w-full max-w-full flex'>
          <div className='flex-1 w-1/3 p-4 overflow-hidden flex flex-col'>
            <div className='flex items-center font-bold text-sm mb-2'>Rośliny Ozdobne</div>
            <p className='text-gray-800 md:text-base text-sm mb-4'>
              W ofercie posiadamy rabaty, drzewa oraz krzewy ozdobne.
            </p>
            <Link className='btn btn-blue mt-auto mb-2' to='/kontakt'>
              Kontakt
            </Link>
          </div>
          <div className='w-2/3 p-4 flex flex-wrap justify-start leading-normal'>
            {data.allFile.edges
              .filter(({ node }) =>
                rosliny.find(
                  item => item.name === node.name && item.dir === node.relativeDirectory,
                ),
              )
              .map(({ node }) => (
                <GatsbyImage
                  className='w-56 h-56 mr-2 mb-2 border-solid border-gray-200 border-4 rounded-sm'
                  fluid={node.childImageSharp.fluid}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage
